/* eslint-disable no-restricted-globals */
import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import deno_logo from './deno_logo.png'

function Footer() {
  return (
    <div className='footer-container'>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
            <img src={deno_logo} className="deno_logo" alt="logo" />
              Deno-Redlock
              {/* <i class='fab fa-typo3' /> */}
            </Link>
          </div>
          <small class='website-rights'>Product developed under tech accelerator OS Labs</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
