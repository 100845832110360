/* eslint-disable no-restricted-globals */
/* eslint-disable react/style-prop-object */
import React from 'react';
import anna from './images/anna_profile.jpg'
import cody from './images/cody_profile.jpg'
import michael from './images/michael_profile.jpg'
import jiayi from './images/jiayi_profile.jpg'
import './team.css';

export default function Services() {
  return (
    <div className='team'>
      <h1 className='teamTitle'>Meet Our Team</h1>
     
      <div class="row">

        <div class="column">
          <div class="card">
            <img className='profilePic' src={anna} alt="Anna"/>
            <div class="container">
              <h2 className='memberName'>Anna Shen</h2>
              <p class="title">Software Engineer</p>
              <p>
                <button 
                class="button" 
                onClick={()=>{location.href = 'http://www.linkedin.com/in/ashen0426';}}>
                  LinkedIn
                </button>
                <button 
                class="button" 
                onClick={()=>{location.href = 'https://github.com/ashen0426';}}>
                  GitHub
                </button>
              </p>
            </div>
          </div>
        </div>
     
        <div class="column">
          <div class="card">
            <img className='profilePic' src={cody} alt="Cody"/>
            <div class="container">
              <h2 className='memberName'>Cody Schexnider</h2>
              <p class="title">Software Engineer</p>
              <p>
                <button 
                class="button" 
                onClick={()=>{location.href = 'https://www.linkedin.com/in/cody-schexnider-2402701a3/';}}>
                  LinkedIn
                </button>
                <button 
                class="button" 
                onClick={()=>{location.href = 'https://github.com/cdschexnide';}}>
                  GitHub
                </button>
              </p>
            </div>
          </div>
        </div>
     
     
        <div class="column">
          <div class="card">
            <img className='profilePic' src={jiayi} alt="Jiayi"/>
            <div class="container">
              <h2 className='memberName'>Jiayi Zhang</h2>
              <p class="title">Software Engineer</p>
              <p>
                <button 
                class="button" 
                onClick={()=>{location.href = 'https://www.linkedin.com/in/jiayi-zhang-87819173/';}}>
                  LinkedIn
                </button>
                <button 
                class="button" 
                onClick={()=>{location.href = 'https://github.com/onlinezyc';}}>
                  GitHub
                </button>
              </p>
            </div>
          </div>
        </div>
      
        <div class="column">
          <div class="card">
            <img className='profilePic' src={michael} alt="Michael"/>
            <div class="container">
              <h2 className='memberName'>Michael Watson</h2>
              <p class="title">Software Engineer</p>
              <p>
                <button 
                class="button" 
                onClick={()=>{location.href = 'https://www.linkedin.com/in/mdwatson988/';}}>
                  LinkedIn
                </button>
                <button 
                class="button" 
                onClick={()=>{location.href = 'https://github.com/mdwatson988';}}>
                  GitHub
                </button>
              </p>
            </div>
          </div>
        </div>

      </div> 
    </div>    
  );
}
