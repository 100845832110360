/* eslint-disable no-restricted-globals */
import React from 'react';
import './product.css';
import deno_icon from './images/deno-icon.png'
import redis_icon from './images/redis-icon.png'
import denoland_icon from './images/denoland-icon.png'
import redlock_object from './images/redlock_object.png'
import autoMethod from './images/autoMethod.png'
import manualMethod from './images/manualMethod.png'
import errorHandling from './images/errorHandling.png'
import lockIcon from './images/lock-icon.png'
import unlockIcon from './images/unlock-icon.png'
import extendIcon from './images/extend-icon.png'

export default function Products() {
  return (
    <div className='products'>
      <div className='container'>
      <div className='intro'>
        <p className='title'>Getting started:</p>
        <ul>
          <li> Install Deno   <img 
            onClick={()=>{location.href = 'https://deno.land/manual/getting_started/installation';}}
            height="200px" width="400px"
            className='icons' src={deno_icon} alt="Deno"/>
          </li>

          <li> Install Redis  <img 
            onClick={()=>{location.href = 'https://redis.io/docs/getting-started/';}}
            className='icons' src={redis_icon} alt="Redis"/>
          </li>

          <li> Import URL from Deno.land   <img 
            onClick={()=>{location.href = 'https://deno.land/x/redlock@v1.0-beta';}}
            height="200px" width="400px"
            className='icons' src={denoland_icon} alt="Denoland"/>
          </li>
        </ul>
      </div>

      <div className='redlockObject'>
        <p className='title'> To instantiate a Redlock object: </p>
        <p> Passing an array of at least one Redis client, and an optional options object </p>
        <img src={redlock_object} height="200px" width="400px" alt = "RedlockObject"/>
      </div>

      <div className="lockProperties">
        <p className='title'> Redlock methods: </p>
        <ul>
           <li display="inline"> Using  
          </li>
          <li display="inline"> Acquire  
          </li>
          <li display="inline"> Extend  
            </li>
          <li display="inline"> Release  
            </li>
        </ul>
      </div>

      <div className='lockUsage'>
        <p className='title'> Two ways to use Redlock: </p>
        <div className='lockUsageList'>
          <p> The "using" method, by passing in an array of resources to be locked & desired duration </p>
          <img src={autoMethod} height="200px" width="400px" alt = "AutoMethod"/>
          <br/>
          <p> Alternatively, manually acquire, extend and release the lock </p>
          <img src={manualMethod} height="200px" width="400px" alt = "ManualMethod"/>
        </div>
      </div>
      
      <div className="addtionalFeature">
        <p className='title'> Error handling </p>
        {/* <p > Error handling </p> */}
        <img src={errorHandling} height="200px" width="400px"  alt = "ErrorHandling"/>
      </div>


      
      </div>
    </div>
  );
}
