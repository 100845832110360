import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';


function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted />
      {/* <div className='descriptions'> */}
        <h1>Deno DLM</h1>
        {/* <h2>From Developers to Developers</h2> */}
        <p>Redlock Implementation for Deno</p>
        {/* <h2>From developers to developers</h2> */}
      {/* </div> */}
      
      <div className='hero-btns'>
      <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          // eslint-disable-next-line no-restricted-globals
          onClick={()=>{location.href = 'https://medium.com/@mdwatson988/deno-redlock-708f4ac5b4c4';}}
        >
          {/* <img src={linkedin_Logo} className="linkedin_Logo" alt="logo" /> */}
          Medium
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          // eslint-disable-next-line no-restricted-globals
          onClick={()=>{location.href = 'https://www.linkedin.com/company/denodlm';}}
        >
          {/* <img src={linkedin_Logo} className="linkedin_Logo" alt="logo" /> */}
          LinkedIn
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          // eslint-disable-next-line no-restricted-globals
          onClick={()=>{location.href = 'https://github.com/oslabs-beta/Deno-Redlock';}}
        >
          GitHub 
          {/* <i className='far fa-play-circle' /> */}
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
